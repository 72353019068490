<template>
    <el-dialog custom-class="viewer-top-padding-decrease" v-if="useDialog" :visible="visible" style="padding: 1em 20em" :fullscreen="true" @close="handleClose" :before-close="handleClose">
        <Viewer v-loading="loading" ref="tuiViewer" :initial-value="answer" />
    </el-dialog>
    <Viewer v-loading="loading" v-else ref="tuiViewer" :initial-value="answer" />
</template>

<script>
import "@toast-ui/editor/dist/toastui-editor-viewer.css";

import { Viewer } from "@toast-ui/vue-editor";
export default {
    name: "AnswerViewer",
    components: {
        Viewer,
    },
    props: {
        useDialog: {
            type: Boolean,
            default: true,
        },
        answer: {
            type: null,
            default: null,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showViewer: false,
            localAnswer: "",
        };
    },
    methods: {
        handleClose() {
            this.$emit("close");
        },
    },
    watch: {
        answer(newValue) {
            if (newValue) {
                this.$refs.tuiViewer ? this.$refs.tuiViewer.invoke("setMarkdown", newValue) : null;
            }
        },
    },
};
</script>

<style>
.viewer-top-padding-decrease .el-dialog__body {
    padding: 5px 20px;
}
</style>
